.button.inventoryErrors {
    background-color: pink;
    border: 1px red solid;
}

.button.badge.success:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: .7em;
    background: rgba(91, 240, 0, 0.97);
    color: white;
    width: 8px;
    height: 8px;
    text-align: center;
    line-height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 10px #4c9724;
}

.button.badge.alert:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: .7em;
    background: rgba(255, 24, 0, 0.97);
    color: white;
    width: 8px;
    height: 8px;
    text-align: center;
    line-height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 10px #974a65;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fffafa;
}

